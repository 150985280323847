<template>
  <div class="Home">
    <page-header/>
    <section class="uk-section uk-padding-remove">
      <div class="uk-container">
        <div class="uk-text-left" uk-grid>
          <div class="uk-width-auto">
            <h4 class="qList">{{ $t("orgaExam.results.eName") }} : {{ examDetail.examName }}</h4>
          </div>
          <div class="uk-width-expand uk-flex uk-flex-right">
            <button type="button" @click.prevent="notGroup()" class="uk-button uk-button-primary uk-button-small" >
              <span v-if="groupFalse == true">{{ $t("groupView") }}</span>
              <span v-else>{{ $t("groupView2") }}</span>
              <span class="uk-margin-small-left" uk-icon="grid" uk-tooltip="Grid"></span>
            </button>
            <button type="button" @click.prevent="gridview()"
                    class="uk-button uk-button-primary uk-button-small uk-margin-left">
              <span>{{ $t("gridView") }}</span>
              <span class="uk-margin-small-left" uk-icon="grid" uk-tooltip="Grid"></span>
            </button>
            <button type="button" class="uk-button uk-button-default uk-button-small uk-padding-remove uk-margin-left"
                    style="background: #01b0ff;">
              <span @click="reload()" class="searchIcon" uk-icon="refresh"></span>
            </button>
          </div>
        </div>
        <table class="uk-table uk-table-responsive white">
            <tbody>
            <tr style="border-top: 3px solid rgb(1, 176, 255) !important;">
              <td class="tit">{{ $t("orgaExam.results.superViser") }}</td>
              <td class="tit2">{{ examDetail.regUser }}</td>
              <td class="tit">{{ $t("orgaExam.results.qCount") }}</td>
              <td class="tit2">{{ examDetail.qCount }}</td>
              <td class="tit">{{ $t("orgaExam.results.score") }}</td>
              <td class="tit2">{{ examDetail.totalScore }}</td>
            </tr>
            <tr>
              <td class="tit">{{ $t("orgaExam.results.sLifeT") }}</td>
              <td class="tit2">{{ examDetail.examSdate|moment }}</td>
              <td class="tit">{{ $t("orgaExam.results.eLifeT") }}</td>
              <td class="tit2">{{ examDetail.examEdate|moment }}</td>
              <td class="tit">{{ $t("orgaExam.results.eTime") }}</td>
              <td class="tit2">{{ examDetail.examTime }} {{ $t("orgaExam.results.minute") }}</td>
            </tr>
            <tr>
              <td class="tit">{{ $t("beforeExam") }}</td>
              <td class="tit2">
                <label class="uk-label uk-label-warning uk-text-capitalize" style="width: auto">
                  {{ examDetail.ecodeUsed - examDetail.startedcount - examDetail.finishcount}}
                </label>
              </td>
              <td class="tit">{{ $t("onExam") }}</td>
              <td class="tit2">
                <label class="uk-label uk-label-primary uk-text-capitalize">
                  {{examDetail.startedcount }}</label>
              </td>
              <td class="tit">{{ $t("finish") }}</td>
              <td class="tit2">
                <label class="uk-label uk-label-success uk-text-capitalize">
                  {{ examDetail.finishcount }}
                </label>
              </td>
            </tr>
            <tr>
              <td class="tit">{{ $t("orgaExam.results.applic") }}</td>
              <td class="tit2">{{ examDetail.ecodeCount }} {{ $t("orgaExam.results.per") }}</td>
              <td class="tit">{{ $t("orgaExam.results.toEx") }}</td>
              <td class="tit2">{{ examDetail.ecodeUsed }} {{ $t("orgaExam.results.per") }}</td>
              <td class="tit" v-show="examDetail.datasize != undefined"> {{ $t("orgaExam.register.datasize") }}</td>
              <td class="tit2"><span class="uk-text-capitalize" v-show="examDetail.datasize != undefined">{{examDetail.datasize}} MB</span></td>
              <!--            </td><td class="tit">{{$t("orgaExam.results.status")}}</td>-->
              <!--            <td class="tit2"><span class="uk-text-capitalize">{{examDetail.examStatus}}</span></td>-->
            </tr>
            </tbody>
          </table>
      </div>
      <div class="uk-container uk-margin-top">
        <div class="uk-flex uk-flex-left" v-if="this.activeGroup.id != 0">
          <button type="button" @click="ublMeet()"
                  class="uk-button uk-button-danger uk-button-small uk-margin-right">
            UBL MEET
          </button>
        </div>
        <div class="uk-flex uk-flex-right uk-margin-bottom">
          <!--                        <input @change="callZip($event)" name="file" type="file"-->
          <!--                            style="opacity: 0; position: relative; left: 250px; top: 5px;">-->
          <button type="button" @click="offlineData()"
                  class="uk-button uk-button-primary uk-button-small uk-margin-right" ><span
              uk-icon="icon: download"></span>
            <span>{{ $t('appOffData') }}</span>
          </button>

          <button type="button" @click="callZipAll()"
                  class="uk-button uk-button-primary uk-button-small uk-margin-right" ><span
              uk-icon="icon: upload"></span>
            <span>{{$t("zipUploadCallAll")}}</span>
          </button>
          <button type="button" @click="callZip()"
                  class="uk-button uk-button-primary uk-button-small uk-margin-right" ><span
              uk-icon="icon: upload"></span>
            <span>{{$t("zipUploadCall")}}</span>
          </button>
          <button type="button" @click="jsonDownload()"
                  class="uk-button uk-button-primary uk-button-small uk-margin-right"
                  v-if="examDetail.isTms == '1'"><span
              uk-icon="icon: download"></span>
            <span>Json download</span>
          </button>
          <button type="button" @click="resultDownload()"
                  class="uk-button uk-button-primary uk-button-small uk-margin-right"
                  ><span uk-icon="icon: download"></span>
            <span>{{ $t("orgaExam.results.result") }}</span>
          </button>
          <button type="button" @click="extraTimeModal()" class="uk-button uk-button-primary uk-button-small"
                   v-if="examDetail.realExam == 1"><span uk-icon="icon: add"></span>
            <span>{{ $t("setExtraTime") }}</span>
          </button>
        </div>
        <div class="uk-card uk-card-default uk-box-shadow-medium uk-overflow-auto" v-show="groupFalse== true">
          <header class="uk-card-header"
                  style="border-top: 3px solid rgb(1, 176, 255) !important; padding: 10px 20px;">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
              <div class="uk-width-1-1 uk-width-expand@s uk-flex uk-flex-center uk-flex-left@s uk-text-small">
                <span class="uk-flex uk-flex-middle uk-margin-right">{{ $t("show") }}</span>
                <div uk-form-custom="target: > * > span:first-child">
                  <select @change="listCount()" class="sortBy uk-margin-remove uk-select" v-model="size">
                    <option :value="25">25</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                    <option :value="200">200</option>
                  </select>
                  <button type="button" class="uk-button uk-button-default btn-sortBy"
                          tabindex="-1" style="width: 80px;">
                    <span uk-icon="icon: chevron-down"></span>
                  </button>
                </div>
              </div>
              <div class="uk-width-1-1 uk-width-expand@s uk-flex uk-flex-center uk-flex-left@s uk-text-small">
                <span class="uk-flex uk-flex-middle uk-margin-right">{{ $t("sort") }}</span>
                <div uk-form-custom="target: > * > span:first-child">
                  <select @change="checkOrder(sortt)" class="sortBy uk-margin-remove uk-select" v-model="sortt">
                    <option value="name">{{ $t("name") }}</option>
                    <option value="user_id">{{ $t("userId") }}</option>
                    <option value="status">{{ $t("Status") }}</option>
                    <option value="alert_cnt">{{ $t("AIalert") }}</option>
                    <option value="score">{{ $t("Score") }}</option>
                  </select>
                  <button type="button" class="uk-button uk-button-default btn-sortBy"
                          tabindex="-1" style="width: 80px;">
                    <span uk-icon="icon: chevron-down"></span>
                  </button>
                </div>
              </div>
              <div class="uk-width-1-1 uk-width-expand@s uk-flex uk-flex-center uk-flex-left@s uk-text-small">
                <span class="uk-flex uk-flex-middle uk-margin-right">Refresh</span>
                <div uk-form-custom="target: > * > span:first-child">
                  <select class="sortBy uk-margin-remove uk-select" v-model="rCount"
                      @change="resetTime()">
                    <option :value="0">off
                    </option>
                    <option :value="10">10
                    </option>
                    <option :value="30">30
                    </option>
                    <option :value="60">60
                    </option>
                    <option :value="120">120
                    </option>
                  </select>
                  <button type="button" class="uk-button uk-button-default btn-sortBy"
                          tabindex="-1" style="width: 80px;">
                    <span uk-icon="icon: chevron-down"></span>
                  </button>
                </div>
              </div>
              <div class="uk-width-1-1 uk-width-auto@s uk-flex uk-flex-center uk-flex-middle">
                <div uk-form-custom="target: > * > span:first-child" class=" uk-margin-right">
                  <div style="min-width: 200px">
                    <input style="height: 40px;cursor: pointer" readonly type="text"
                           :value="activeGroup.groupName"
                           placeholder="Select group"
                           class="uk-width-1-1 uk-select">
                    <div style="padding: 0px 10px;"
                         data-uk-dropdown="mode:click; pos: bottom-justify">
                      <div class="uk-search uk-search-default uk-width-1-1">
                        <span class="uk-search-icon-flip" uk-search-icon></span>
                        <input class="uk-search-input" v-model="value" type="search"
                               placeholder="Search...">
                      </div>
                      <ul style="max-height: 150px;overflow-y: auto;"
                          class="uk-nav uk-nav-default" v-if="resultQuery.length > 0">
                        <template v-for="(item, index) in resultQuery">
                          <li :key="index"
                              :class="{ 'uk-active': item.id == activeGroup.id }">
                            <a @click="selectGroup(item)" href="javascript:void(0)">{{ item.groupName }}</a>
                          </li>
                        </template>
                      </ul>
                      <ul class="uk-nav uk-nav-default uk-padding-small" v-else>
                        <li class="uk-text-center">Empty.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="uk-margin-right" uk-form-custom="target: > * > span:first-child"
                     v-show="examVar.length > 1">
                  <select @change="loaddata(currentPage)" class="sortBy uk-select"
                          v-model="selectVar">
                    <template v-for="(iten,index) in examVar">
                      <option :key="index" :value="iten">{{ iten }}</option>
                    </template>
                  </select>
                  <button
                      class="uk-button uk-button-default btn-sortBy"
                      style="min-width: 60px;"
                      tabindex="-1">
                    <span uk-icon="icon: chevron-down"></span>
                  </button>
                </div>
                <div class="uk-margin-small-right" uk-form-custom="target: > * > span:first-child">
                  <select @change="statusSearch()" class="sortBy uk-select"
                          v-model="statusfinish">
                    <option :value="3">{{ $t("status") }}</option>
                    <option :value="0">{{ $t("beforeExam") }}</option>
                    <option :value="1">{{ $t("onExam") }}</option>
                    <option :value="2">{{ $t("finish") }}</option>
                  </select>
                  <button
                      class="uk-button uk-button-default btn-sortBy"
                      style="min-width: 150px;"
                      tabindex="-1">
                    <span uk-icon="icon: chevron-down"></span>
                  </button>
                </div>
                <div class="uk-search uk-search-default uk-margin-left">
                  <input :placeholder="$t('search')" class="uk-search-input " type="search"
                         v-model="filter" @keyup.enter="loaddata(1)">
                </div>
                <button type="button" class="uk-button uk-button-default uk-button-small uk-padding-remove uk-margin-left"
                        style="background: #01b0ff;">
                  <span @click="searchFilter()" class="searchIcon" uk-icon="search"></span>
                </button>
                <button type="button" class="uk-button uk-button-default uk-button-small uk-padding-remove uk-margin-small-left"
                        style="background: #01b0ff;">
                  <span @click="defaultSetting()" class="searchIcon" uk-icon="refresh"></span>
                </button>
              </div>
            </div>
          </header>
          <div class="uk-card-body uk-padding-remove"  style="min-height: 300px;">
            <table class="uk-table uk-table-responsive uk-table-small">
              <thead style="background: #01b0ff">
              <tr class="col" style="border-top: none; border-bottom: none">
                <th class="uk-text-center">No</th>
                <th @click="orderTable('name')" class="uk-text-center">
                  {{ $t("userName") }}
                  <img alt="" height="16" src="../../assets/icons/asc.png"
                       v-if="tableorder.sort == 'name' && tableorder.ascordesc == false"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/desc.png"
                       v-else-if="tableorder.sort == 'name' && tableorder.ascordesc == true"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/sort1.png" v-else width="16"/>
                </th>
                <th @click="orderTable('user_id')" class="uk-text-center uk-visible@l" style="min-width: 85px;">{{ $t("userId") }}
                  <img alt="" height="16" src="../../assets/icons/asc.png"
                       v-if="tableorder.sort == 'user_id' && tableorder.ascordesc == false"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/desc.png"
                       v-else-if="tableorder.sort == 'user_id' && tableorder.ascordesc == true"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/sort1.png" v-else width="16"/>
                </th>
                <th @click="orderTable('department')" class="uk-text-center" style="min-width: 90px;">
                  {{ $t("orgaExam.results.department") }}
                  <img alt="" height="16" src="../../assets/icons/asc.png"
                       v-if="tableorder.sort == 'department' && tableorder.ascordesc == false"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/desc.png"
                       v-else-if="tableorder.sort == 'department' && tableorder.ascordesc == true"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/sort1.png" v-else width="16"/>
                </th>
                <th @click="orderTable('login_date')" class="uk-text-center">
                  {{ $t("orgaExam.results.lastlogin") }}
                  <img alt="" height="16" src="../../assets/icons/asc.png"
                       v-if="tableorder.sort == 'login_date' && tableorder.ascordesc == false"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/desc.png"
                       v-else-if="tableorder.sort == 'login_date' && tableorder.ascordesc == true"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/sort1.png" v-else width="16"/>
                </th>
                <th class="uk-text-center" @click="orderTable('status')">{{ $t("studentHome.status")}}
                  <img alt="" height="16" src="../../assets/icons/asc.png"
                       v-if="tableorder.sort == 'status' && tableorder.ascordesc == false"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/desc.png"
                       v-else-if="tableorder.sort == 'status' && tableorder.ascordesc == true"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/sort1.png" v-else width="16"/>
                </th>
                <th class="uk-text-center">{{ $t("signature")}}</th>
<!--                <th class="uk-text-center">{{ $t("record")}}</th>-->
                <th @click="orderTable('alert_cnt')" class="uk-text-center">{{ $t("aiAlert") }}
                  <img alt="" height="16" src="../../assets/icons/asc.png"
                       v-if="tableorder.sort == 'alert_cnt' && tableorder.ascordesc == false"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/desc.png"
                       v-else-if="tableorder.sort == 'alert_cnt' && tableorder.ascordesc == true"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/sort1.png" v-else width="16"/>
                </th>
                <th @click="orderTable('correct')" class="uk-text-center">{{ $t("coor") }}
                  <img alt="" height="16" src="../../assets/icons/asc.png"
                       v-if="tableorder.sort == 'correct' && tableorder.ascordesc == false"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/desc.png"
                       v-else-if="tableorder.sort == 'correct' && tableorder.ascordesc == true"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/sort1.png" v-else width="16"/>
                </th>
                <th @click="orderTable('score')" class="uk-text-center">
                  {{ $t("orgaExam.results.corAns") }}
                  <img alt="" height="16" src="../../assets/icons/asc.png"
                       v-if="tableorder.sort == 'score' && tableorder.ascordesc == false"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/desc.png"
                       v-else-if="tableorder.sort == 'score' && tableorder.ascordesc == true"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/sort1.png" v-else width="16"/>
                </th>
                <th class="uk-text-center">...</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(exp,index) in examinee">
                <tr :key="index" class="uk-text-center"
                    style="border-bottom: none; border-color: #c2c5cc;">
                    <td>
                      <span v-if="currentPage == 0">
                        {{ index + 1 }}
                      </span>
                      <span v-else>
                        {{ ((currentPage * size) + index) - size + 1 }}
                      </span>
                    </td>
                    <td>
                      <span :title="exp.name"
                            v-if="exp.name !=null">{{ exp.name.slice(0, 10) }}</span>
                      <span v-else> ....</span>
                    </td>
                    <td class="uk-visible@l">
                      <p :title="exp.username" v-if="exp.username != null ">
                        {{exp.username}}</p>
                      <span v-else> ....</span>
                    </td>
                    <td>
                      <span v-if="exp.department != null || exp.department != ''">
                          {{ exp.department }}
                      </span>
                      <span v-else>
                          ....
                      </span>
                    </td>
                    <td>
                      <span v-if="exp.last_login_date != null">
                          {{ exp.last_login_date | moment }}
                      </span>
                      <span v-else>
                          ....
                      </span>
                    </td>
                    <td>
                      <label class="uk-label uk-label-danger uk-text-capitalize"
                             style="width: 190px"
                             v-if="exp.started_date == null  && exp.finish == '0' && exp.zip == null">
                        {{ $t('beExam') }}
                      </label>
                      <label class="uk-label uk-label-warning uk-text-capitalize"
                             style="width: 190px"
                             v-else-if="exp.started_date == null  && exp.finish == '0' && exp.zip!=null">
                        {{ $t('zipFinish') }}
                      </label>
                      <label class="uk-label uk-label-primary uk-text-capitalize" style="width: 190px"
                             v-else-if="exp.started_date != null && exp.finish == '0'">
                        {{ $t('onExam') }}
                        ({{ exp.started_date | moment }})
                      </label>
                      <label class="uk-label uk-label-success uk-text-capitalize" style="width: 190px"
                             v-else> {{ $t('finished') }}
                        ({{ exp.finish_date | moment }})</label>
                    </td>
                    <td>
                      <img @click="signShow(exp.signature)" src="../../assets/signature.png"
                           style="width: 40px;" v-if="exp.signature !=null && exp.signature != ''"/>
                      <span v-else> N/A </span>
                    </td>
<!--                    <td>-->
<!--                      <img src="../../assets/icons/freeIcon1.png"-->
<!--                           v-if="exp.qr_content == ''"-->
<!--                           width="40">-->
<!--                      <img src="../../assets/icons/freeIcon2.png"-->
<!--                           v-else-if="exp.qrStatus != 10"-->
<!--                            width="40" @click="moreDetails(exp)">-->
<!--                      <img src="../../assets/icons/freeIcon3.png"  v-else-->
<!--                           width="40" @click="moreDetails(exp)">-->
<!--                    </td>-->
                    <td>
                      <div v-if="exp.alert_cnt > 0">
<!--                        https://headpos.nsdai.org/guidemodel/UBTAPI/-->
<!--                        http://localhost/guidemodel/UBTAPI/-->
                        <form action="https://headpos.nsdai.org/guidemodel/UBTAPI/" method="POST"
                              target="_blank"
                              v-if="exp.aifile != ''">
<!--                         todo check ubtcloud.me -->
                          <input :value="`http://192.168.2.27:8080/uploadingDir/aidata/${exp.aifile}`"
                                 name="fileURL" type="hidden"/>
<!--                          todo check mz.ubtcloud.me offline -->
<!--                          <input :value="`http://localhost/uploadingDir/aidata/${exp.aifile}`"-->
<!--                                 name="fileURL" type="hidden"/>-->
                          <button style="border: none; background: #fff; outline: none;"
                                  type="submit">
                             <span class="uk-alert uk-alert-danger uk-padding-small"
                                   style="background-color: #dee2fe;"
                                   v-if="exp.alert_cnt <= 5">{{ exp.alert_cnt }}</span>
                            <span class="uk-alert uk-alert-danger uk-padding-small"
                                  style="background-color: rgb(126 214 253);"
                                  v-else-if="exp.alert_cnt > 5 && exp.alert_cnt <= 10">{{ exp.alert_cnt }}</span>
                            <span class="uk-alert uk-alert-danger uk-padding-small"
                                  style="background-color: #e1ff3c;"
                                  v-else-if="exp.alert_cnt > 10 && exp.alert_cnt <= 20">{{ exp.alert_cnt }}</span>
                            <span class="uk-alert uk-alert-danger uk-padding-small"
                                  style="background-color: #fdcfd8"
                                  v-else-if="exp.alert_cnt > 20">{{ exp.alert_cnt }}</span>
                          </button>
                        </form>
                        <span @click="notfinish()" style="background-color: #dee2fe; padding: 10px;" v-else>
                          {{ exp.alert_cnt }}
                        </span>
                      </div>
                      <button type="button" style="border: none; background: #fff; outline: none;" v-else>
                          <span class="uk-alert uk-alert-danger uk-padding-small"
                                style="background-color: #dee2fe;">0</span>
                      </button>
                    </td>
                    <td>{{ exp.correct_count }}</td>
                    <td>{{ exp.uscore }}</td>
                    <!--                                    <td>-->
                    <!--                                        <img src="../../assets/icons/answers.png" style="margin-left: 5px;"-->
                    <!--                                             @click="popUpStudentDetail(exp.user_id, exp.exam_code, exp.variant)"/>-->
                    <!--                                    </td>-->
                    <td>
                      <button type="button" class="uk-button uk-button-default"
                              style="border-radius: 1em; padding: 0px 5px;">Show
                        <span uk-icon="icon:  triangle-down"></span>
                      </button>
                      <div uk-drop="mode: click">
                        <div class="uk-card uk-card-body uk-card-default uk-padding-small"
                             style="margin-left: 170px; margin-top: -20px; border: 1px solid #b2b2b2;">
                          <ul class="uk-list">
                            <li>
                              <button type="button" @click="popUpStudentDetail(exp.user_id, exp.exam_code, exp.variant)"
                                      class="uk-button uk-button-primary uk-button-small uk-text-capitalize qbtn_b "
                                      style="width: 95px;">
                                {{ $t("orgaExam.results.answers") }}
                              </button>
                            </li>
                            <li class="uk-nav-divider" style="color: #b2b2b2; border-bottom: 1px solid;"></li>
  <!--                          <li>-->
  <!--                            <button type="button" @click="moreDetails(exp)"-->
  <!--                                    class="uk-button uk-button-primary uk-button-small uk-text-capitalize qbtn_b "-->
  <!--                                    style="width: 95px;">-->
  <!--                              {{ $t("orgaExam.results.seeMore") }}-->
  <!--                            </button>-->
  <!--                          </li>-->
                            <li>
                              <button type="button" @click="logs(exp)"
                                      class="uk-button uk-button-primary uk-button-small uk-text-capitalize qbtn_b "
                                      style="width: 95px;">
                                {{ $t("orgaExam.results.log") }}
                              </button>
                            </li>
                            <li>
                              <button type="button" @click="valiReset(exp)" v-if="exp.started_date != null"
                                      class="uk-button uk-button-danger uk-button-small uk-text-capitalize"
                                      style="width: 95px; border-radius: 1em">
                                {{ $t("orgaExam.results.reset") }}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="uk-card uk-card-default uk-box-shadow-medium uk-overflow-auto" v-show="groupFalse== false">
          <header class="uk-card-header"
                  style="border-top: 3px solid rgb(1, 176, 255) !important; padding: 10px 20px;">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
              <div class="uk-width-1-1 uk-width-expand@s uk-flex uk-flex-center uk-flex-left@s uk-text-small">
                <div class="uk-margin-right" uk-form-custom="target: > * > span:first-child"
                     v-show="examVar.length > 1">
                  <select @change="loaddata(currentPage)" class="sortBy uk-select"
                          v-model="selectVar">
                    <template v-for="(iten,index) in examVar">
                      <option :key="index" :value="iten">{{ iten }}</option>
                    </template>
                  </select>
                  <button
                      class="uk-button uk-button-default btn-sortBy"
                      style="min-width: 60px;"
                      tabindex="-1">
                    <span uk-icon="icon: chevron-down"></span>
                  </button>
                </div>
              </div>
              <div class="uk-width-1-1 uk-width-auto@s uk-flex uk-flex-center uk-flex-middle">
                <div class="uk-search uk-search-default uk-margin-left">
                  <input :placeholder="$t('search')" class="uk-search-input " type="search"
                         v-model="depfilter" @keyup.enter="groupView(1)">
                </div>
                <button type="button" class="uk-button uk-button-default uk-button-small uk-padding-remove uk-margin-left"
                        style="background: #01b0ff;">
                  <span @click="depsearchFilter()" class="searchIcon" uk-icon="search"></span>
                </button>
                <button type="button" class="uk-button uk-button-default uk-button-small uk-padding-remove uk-margin-small-left"
                        style="background: #01b0ff;">
                  <span @click="depdefaultSetting(1)" class="searchIcon" uk-icon="refresh"></span>
                </button>
              </div>
            </div>
          </header>
          <div class="uk-card-body uk-padding-remove">
            <table class="uk-table uk-table-responsive uk-table-small">
              <thead style="background: #01b0ff">
              <tr class="col" style="border-top: none; border-bottom: none">
                <th class="uk-text-center">No</th>
                <th class="uk-text-center" @click="deporderTable('depart')">
                  {{ $t("orgaExam.results.department") }}
                  <img alt="" height="16" src="../../assets/icons/asc.png"
                       v-if="deptableorder.sort == 'depart' && deptableorder.ascordesc == false"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/desc.png"
                       v-else-if="deptableorder.sort == 'depart' && deptableorder.ascordesc == true"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/sort1.png" v-else width="16"/>
                </th>
                <th @click="deporderTable('ucount')" class="uk-text-center">
                  {{$t("candidate")}}
                  <img alt="" height="16" src="../../assets/icons/asc.png"
                       v-if="deptableorder.sort == 'ucount' && deptableorder.ascordesc == false"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/desc.png"
                       v-else-if="deptableorder.sort == 'ucount' && deptableorder.ascordesc == true"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/sort1.png" v-else width="16"/>
                </th>
                <th @click="deporderTable('before')" class="uk-text-center">
                  {{$t("beforeExam")}}
                  <img alt="" height="16" src="../../assets/icons/asc.png"
                       v-if="deptableorder.sort == 'before' && deptableorder.ascordesc == false"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/desc.png"
                       v-else-if="deptableorder.sort == 'before' && deptableorder.ascordesc == true"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/sort1.png" v-else width="16"/>
                </th>
                <th @click="deporderTable('onexam')" class="uk-text-center uk-visible@l">
                  {{$t("onExam")}}
                  <img alt="" height="16" src="../../assets/icons/asc.png"
                       v-if="deptableorder.sort == 'onexam' && deptableorder.ascordesc == false"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/desc.png"
                       v-else-if="deptableorder.sort == 'onexam' && deptableorder.ascordesc == true"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/sort1.png" v-else width="16"/>
                </th>
                <th @click="deporderTable('duussan')" class="uk-text-center">
                  {{$t("finishExam")}}
                  <img alt="" height="16" src="../../assets/icons/asc.png"
                       v-if="deptableorder.sort == 'duussan' && deptableorder.ascordesc == false"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/desc.png"
                       v-else-if="deptableorder.sort == 'duussan' && deptableorder.ascordesc == true"
                       width="16"/>
                  <img alt="" height="16" src="../../assets/icons/sort1.png" v-else width="16"/>
                </th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(dep,index) in departs">
                <tr :key="index" class="uk-text-center"
                    style="border-bottom: none; border-color: #c2c5cc;">
                  <td>
                    <span v-if="depPage == 0">
                      {{ index + 1 }}
                    </span>
                    <span v-else>
                      {{ ((depPage * depsize) + index) - depsize + 1 }}
                    </span>
                  </td>
                  <td>
                    <span>{{ dep.department }}</span>
                  </td>
                  <td>
                    <span>{{ dep.ucount }}</span>
                  </td>
                  <td>
                    <span>{{ dep.before }}</span>
                  </td>
                  <td>
                    <span>{{ dep.onexam }}</span>
                  </td>
                  <td>
                    <span style="color: red">{{ dep.duussan }}</span>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="uk-card-footer uk-margin-remove-left uk-padding-remove" uk-grid v-show="groupFalse== false">
          <div class="uk-margin-top uk-padding-remove uk-text-center">
            <div class="uk-grid-column-large uk-grid-row-large uk-margin-remove uk-padding-remove" uk-grid>
              <div :key="index" class="uk-padding-remove uk-margin-small-top"
                   v-for="(item,index) in depPages">
                <button type="button" :class="{'changed': depPage === item}" @click="deppageselect(item)"
                        class="uk-button uk-button-small uk-margin-left "
                        style="min-width: 45px;">{{ item }}
                </button>
              </div>
            </div>
          </div>
          <span class="uk-flex uk-flex-middle uk-width-1-1 uk-padding-remove uk-margin-large-bottom">Showing
              <span v-if="depPage == 0"
                    class="uk-margin-small-left uk-margin-small-right">  {{ depPage + 1 }}  </span>
              <span v-else class="uk-margin-small-left uk-margin-small-right">  {{ depPage }}  </span>
              to {{ depPages }} of {{ depElement }} entries</span>
        </div>
        <div class="uk-card-footer uk-margin-remove-left uk-padding-remove" uk-grid v-show="groupFalse== true">
          <div class="uk-margin-top uk-padding-remove uk-text-center">
            <div class="uk-grid-column-large uk-grid-row-large uk-margin-remove uk-padding-remove" uk-grid>
              <div :key="index" class="uk-padding-remove uk-margin-small-top"
                   v-for="(item,index) in pages">
                <button type="button" :class="{'changed': currentPage === item}" @click="pageselect(item)"
                        class="uk-button uk-button-small uk-margin-left "
                        style="min-width: 45px;">{{ item }}
                </button>
              </div>
            </div>
          </div>
          <span class="uk-flex uk-flex-middle uk-width-1-1 uk-padding-remove uk-margin-large-bottom">Showing
              <span v-if="currentPage == 0"
                    class="uk-margin-small-left uk-margin-small-right">  {{ currentPage + 1 }}  </span>
              <span v-else class="uk-margin-small-left uk-margin-small-right">  {{ currentPage }}  </span>
              to {{ pages }} of {{ totalElements }} entries</span>
        </div>
      </div>
    </section>

    <div id="drc" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;">Result Download</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <div>
            <h4 class="uk-text-center"> Your download file is ready. </h4> <br>
            <div class="uk-flex uk-flex-center">
              <button class="uk-button uk-button-primary" @click="rDown('Excel')">Result Excel File</button>
              <button class="uk-button uk-button-primary uk-margin-left" @click="rDown('Files')">All Uploaded Files</button>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </div>

    <div id="download" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;"> Download</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <p style="font-size: 19px;">
            Your download file is ready. <br>
            Please click to download.
          </p>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >
            {{ $t("close") }}
          </button>
          <button type="button" @click="closeModal('all')"
                  class="uk-button uk-button-primary uk-button-small">Download
          </button>
        </div>
      </div>
    </div>

    <div id="sign-modal" uk-modal>
      <div class="uk-modal-dialog" style="width: 1200px;">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;"> Signature </h2>
        </div>
        <div class="uk-modal-body uk-text-center" v-if="signa !=null && signa != ''">
          <img :src="`/uploadingDir/signature/${signa}`"
               style="border: 3px solid #dee2de;"/>
        </div>
        <div v-else>
          Empty
        </div>
      </div>
    </div>

    <div id="videoDownloadSection" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;">Download</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <p style="font-size: 19px;">
            Your download file is ready. <br>
            Please click to download.
          </p>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >
            {{ $t("close") }}
          </button>
          <button type="button" @click="closeModal('all')"
                  class="uk-button uk-button-primary uk-button-small">Download
          </button>
        </div>
      </div>
    </div>

    <div class="modal" id="myModal" v-show="moreinfo.display">
      <!-- Modal content -->
      <div class="modal-content">
        <span @click="moreinfo.display = false" class="close">&times;</span>
        <div>
          <ul class="uk-list uk-list-large uk-list-striped">
            <li><label><b>Name</b></label> : {{ moreinfo.examineeName }}</li>
            <li><label><b>User Id</b></label> : {{ moreinfo.username }}</li>
            <li v-if="moreinfo.image != null || moreinfo.image != ''"><label><b>Photo</b></label>
              <div uk-grid>
                <div>
                  <img :src="`/uploadingDir/`+ moreinfo.image"
                       class="uk-responsive-width" style="max-width: 150px; max-height: 150px;"
                       v-if="moreinfo.image != '' && moreinfo.image != null"/>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" @click="currentMedia(moreinfo.examineeName,
                                        moreinfo.examineeId, moreinfo.examtime, 'mediaGetFile')"
                  class="uk-button uk-button-default">
            {{$t("finalVideo")}}
          </button>
          <button type="button" @click="currentMedia(moreinfo.examineeName,
                                        moreinfo.examineeId, moreinfo.examtime, 'currentMedia')"
                  class="uk-button uk-button-primary uk-margin-left">
            {{$t("rtVideo")}}
          </button>
        </div>
      </div>
    </div>
    <div class="modal uk-padding-remove" v-show="frameinfo.display" style="padding-top: 44px !important;">
      <!-- Modal content -->
      <div class="modal-content" style="position: relative; width: 95%">
        <span @click="frameclose" class="close" style="position: absolute;top: 0;right: 15px;">&times;</span>
        <div class="embed-responsive embed-responsive-16by9 z-depth-1-half" style="min-height: 500px">
          <div  class="uk-flex uk-margin-bottom uk-margin-left" v-show="videosUrl.length > 0" >
            <div v-for="(item,index) in videosUrl" :key="item">
              <button class="uk-button uk-button-small uk-margin-right"
                      @click="indCh(index)" :class="{'uk-button-primary' : chind == index}">
                {{ index + 1 }} video</button>
            </div>
          </div>
          <div v-for="(item,index) in videosUrl" :key="index" v-show="videosUrl.length > 0">
            <iframe frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;microphone;camera"
                    allowfullscreen  :src="item" v-if="chind == index" style="height: 780px;width: 100%;"></iframe>
          </div>
          <div v-show="videoframe != ''">
            <iframe frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;microphone;camera"
                    allowfullscreen  :src="videoframe" class="embed-responsive-item" style="height: 780px;width: 100%;">
            </iframe>
          </div>
        </div>
      </div>
    </div>
    <div id="mediaModal" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;">Media file play</h2>
        </div>
        <div class="uk-modal-body uk-text-center" style="padding-left: 10px;">
          <table class="uk-table uk-table-striped">
            <thead>
            <tr>
              <th class="uk-text-center">No</th>
              <th class="uk-text-center">Name</th>
              <th class="uk-text-center">URL</th>
            </tr>
            </thead>
            <tbody>
            <tr :key="index" v-for="(item,index) in videosUrl">
              <td>{{ index + 1 }}</td>
              <td>{{ item }}</td>
              <td>
                <button type="button" @click="mediaDownloadURL(item)"
                        class="uk-button uk-button-primary uk-button-small qbtn_b">
                  Play
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </div>
    <div id="studentExtraTime" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;">Extra time send</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <div class="uk-width-1-1 uk-margin-remove-right" uk-grid>
            <div class="uk-width-3-4 uk-flex">
              <h5 class="uk-text-bold">Extra time : </h5>
              <input class="uk-input uk-margin-left" style="height: 25px;  width: 80px;" type="number"
                     v-model="exam.extraTime">
              <span class="uk-margin-left">minutes</span>
            </div>
            <div class="uk-width-1-4 uk-flex uk-flex-right uk-padding-remove">
              <span class="uk-text-right uk-text-bold">Selected : </span>
              <span class="uk-margin-left  uk-text-danger uk-text-bold">{{ userIds.length }}</span>
            </div>
          </div>
          <table class="uk-table uk-table-responsive uk-table-small">
            <thead style="background: #01b0ff">
            <tr>
              <th style="text-align: center">
                <input @click="toggleSelect()" class="uk-checkbox" style="border: 1px solid #fff;"
                       type="checkbox">
              </th>
              <th style="text-align: center">
                N/A
              </th>
              <th style="text-align: center">
                Exam name
              </th>
              <th style="text-align: center">
                Exam code
              </th>
            </tr>
            </thead>
            <tbody>
            <tr :key="ind" v-for="(user,ind) in examinee">
              <td>
                <input :value="user.user_id" @click="select()" class="uk-checkbox"
                       type="checkbox"
                       v-if="user.name != '' && user.finish == '0' && user.started_date != null"
                       v-model="userIds">
              </td>
              <td>
                {{ ind + 1 }}
              </td>
              <td>
                {{ user.name }}
              </td>
              <td>
                {{ user.exam_code }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >
            {{ $t("close") }}
          </button>
          <button type="button" @click="extraTimeSend()"
                  class="uk-button uk-button-primary uk-button-small">send
          </button>
        </div>
      </div>
    </div>
    <div id="student" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;">Student result download</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <p style="font-size: 19px;">
            Your download file is ready. <br>
            Please click to download.
          </p>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >
            {{ $t("close") }}
          </button>
          <button type="button" @click="closeModal('student')"
                  class="uk-button uk-button-primary uk-button-small">Download
          </button>
        </div>
      </div>
    </div>
    <div id="validation" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;">Notice</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <h3>{{ $t('doYouExam') }} </h3>
          <p><span>{{ $t('username') }} : </span> <span>{{ stuName }}</span></p>
          <p><span>{{ $t('orgaExam.orgaList.examCode') }} : </span> <span>{{ stuCode }}</span></p>
        </div>
        <div class="uk-modal-footer uk-text-center">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >
            {{ $t("close") }}
          </button>
          <button type="button" class="uk-button uk-button-primary uk-button-small uk-margin-left "
                  @click="resetUser">Reset
          </button>
        </div>
      </div>
    </div>
    <div id="logs" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;">{{ logData.name }} ({{ logData.examCode }})</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <ul class="uk-list uk-list-striped" v-if="logData.logs.length > 0">
            <li :key="index" v-for="(item,index) in logData.logs">
              {{ item.action }} - {{ item.date }}
            </li>
          </ul>
          <div class="uk-alert-primary" uk-alert v-else>
            <a class="uk-alert-close"></a>
            <p>Empty.</p>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </div>
    <div id="zipzip" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700; color: rgb(1, 176, 255);">{{$t("zipUploadCall")}}</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <div class="js-upload uk-placeholder uk-text-center">
            <span class="uk-margin-small-right" uk-icon="icon: cloud-upload"></span>
            <span class="uk-text-middle">{{ zip.fileName.length > 0 ? zip.fileName : 'Choose zip file' }}</span>
            <div uk-form-custom>
              <input name="file" style="opacity: 0" @change="zipZip($event)" type="file">
              <span class="uk-link uk-margin-small-left">{{$t("chooseFile")}}</span>
            </div>
          </div>
          <progress class="uk-progress" id="js-progressbar" max="100" value="0"></progress>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >
            {{$t("close")}}
          </button>
          <button type="button" @click="resZip()"
                  class="uk-button uk-button-primary uk-button-small uk-margin-left" >
            {{$t("upload")}}
          </button>
        </div>
      </div>
    </div>
    <div id="zipzipall" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default"  uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700; color: rgb(1, 176, 255);">{{$t("zipUploadCallAll")}}</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <div class="js-upload uk-placeholder uk-text-center">
            <span class="uk-margin-small-right" uk-icon="icon: cloud-upload"></span>
            <span class="uk-text-middle">{{ zipAll.fileName.length > 0 ? zipAll.fileName : 'Choose zip file' }}</span>
            <div uk-form-custom>
              <input name="file" style="opacity: 0" @change="zipZip2($event)" type="file">
              <span class="uk-link uk-margin-small-left">{{$t("chooseFile")}}</span>
            </div>
          </div>
          <progress class="uk-progress" hidden id="js-bar" max="100" value="0"></progress>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >
            {{$t("close")}}
          </button>
          <button type="button" @click="resZipAll()"
                  class="uk-button uk-button-primary uk-button-small uk-margin-left" >
            {{$t("upload")}}
          </button>
        </div>
      </div>
    </div>
    <page-footer/>
  </div>

</template>
<script>
import {mapGetters} from "vuex"
import GroupService from '@/services/GroupService'
import ExamService from '@/services/ExamService'
import moment from "moment"
import axios from 'axios'
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
import UIkit from "uikit";

const PageFooter = () => import("../Organizcmp/OrganizFooter.vue");

export default {
  name: "app",
  components: {
    // vPagination,
    PageHeader,
    PageFooter
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getToken", "currentUser"]),
    resultQuery() {
      if (this.value) {
        return this.stGroups.filter((item) => {
          return this.value.toLowerCase().split(' ').every(v => item.groupName.toLowerCase().includes(v))
        })
      } else {
        return this.stGroups;
      }
    }
  },
  data() {
    return {
      frameinfo: {
        display: false,
        url: ""
      },
      sortt: "user_id",
      chind: 0,
      moreinfo: {
        avg_acc: "",
        max_acc: "",
        avg_acc_id: "",
        max_acc_id: "",
        client: "",
        photo: "",
        photo_id: "",
        examineeName: "",
        examineeId: "",
        examtime: "",
        display: false
      },
      groupFalse: true,
      departs: [],
      examineeResultDetail: [],
      examCode: '',
      listcount: '',
      examDetail: {},
      examineeName: '',
      exam: {
        examineeName: '',
        examCode: '',
        extraTime: '',
      },
      tableorder: {
        sort: '',
        ascordesc: false
      },
      deptableorder: {
        sort: '',
        ascordesc: false
      },
      examinee: [],
      currentPage: 1,
      depPage: 1,
      depsize: 1,
      depPages: 0,
      pages: 0,
      examId: 0,
      ittt: 0,
      examCodeSee: '',
      examineeNameSee: '',
      currentExaminee: '',
      currentExamineeOptions: '',
      downloadUrl: '',
      studentUrl: '',
      signa: '',
      stuName: '',
      stuCode: '',
      stuFinish: '',
      stuUID: '',
      stuVariant: '',
      examVar: [],
      uikitClasses: {
        ul: "uk-pagination",
        li: "",
        liActive: "uk-active",
        liDisable: "uk-disabled",
        button: "page-link"
      },
      Labels: {
        first: "<span uk-icon='chevron-double-left'></span>",
        prev: "<span uk-pagination-previous></span>",
        next: "<span uk-pagination-next></span>",
        last: "<span uk-icon='chevron-double-right'></span>"
      },
      itemsPerPage: 1,
      videosUrl: [],
      selected: [],
      allSelected: false,
      userIds: [],
      examineeResult: [],
      detailsAnswerId: '',
      answerInputedData: '',
      filter: '',
      statusfinish: 3,
      ascordesc: false,
      depascordesc: '',
      currentDate: '',
      size: 25,
      depSize: 25,
      localPath: "/ubtexam/teacher",
      // localPath: 'http://localhost:8084/ubtexam/teacher',
      totalElements: 0,
      depElement: 0,
      selectVar: 'A',
      value: "",
      stGroups: [{groupName: "All group", id: 0}],
      activeGroup: {groupName: "All group", id: 0},
      depfilter: '',
      rCount: 30,
      timer: 0,
      isRunning: false,
      videoframe: '',
      zip: {
        zipFile: null,
        fileName: "",
      },
      zipAll: {
        zipFile: null,
        fileName: "",
      },
      logData: {
        examCode: '',
        name: '',
        logs: []
      }
    };
  },
  created() {
    if (window.location.href.includes('Examinee/ResultView')){
      console.log(window.location.href)
      setInterval(this.incrementTime, 1000)
    }
  },
  mounted() {
    this.examId = this.$route.params.examId;
    this.losfa()
    this.examDetails();
    this.groups()
    this.orderTable('user_id')
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY.MM.DD HH:mm:ss')
      // return moment(date).format('YYYY-MM-DD, h:mm:ss')
    }
  },
  methods: {
    indCh(ii){
      this.chind = ii
    },
    resetTime(){
      this.timer = 0
    },
    incrementTime(){
        this.timer++
        if (this.timer === this.rCount)
        {
          this.timer = 0
          this.examDetails()
          this.loaddata(this.currentPage)
        }
    },
    losfa(){
      var groupVal = false;
      groupVal = localStorage.getItem("groupFalse");
      if (groupVal != null)
      {
        if (groupVal == 'false' && this.groupFalse == true)
        {
          this.groupFalse = !this.groupFalse
          localStorage.setItem("groupFalse", this.groupFalse);
          this.groupView(0)
        }
      }
    },
    notGroup() {
        this.groupFalse = !this.groupFalse
        localStorage.setItem("groupFalse", this.groupFalse)
        this.groupView(0)
    },
    depsearchFilter() {
      this.groupView(0)
    },
    depdefaultSetting() {
      this.examDetails();
      this.loaddata(this.currentPage)
    },
    async groupView(page) {
      try {
        if (page > 0) {
          page = page - 1;
        }
        //depart, ucount, ehelsen,duussan,onexam
        const response = await ExamService.getDepartment({
          page: page,
          examId: this.examId,
          ascordesc: this.depascordesc,
          sortfield: this.deptableorder.sort,
          filter: this.depfilter,
          variant: this.selectVar
        });
        this.departs = response.data.content
        this.depPages = response.data.totalPages;
        this.depElement = response.data.totalElements;
      } catch (error) {
        console.log(error)
      }
    },
    async groups() {
      try {
        const response = await GroupService.groups({orgid: this.currentUser.organizs[0].organizId});
        this.stGroups = [{groupName: "All group", id: 0}];
        response.data.map((item) => {
          this.stGroups.push(item)
        })
      } catch (error) {
        console.log(error)
      }
    },
    selectGroup(item) {
      if (this.activeGroup.id != item.id) {
        this.activeGroup = item;
        this.currentPage = 1;
        this.examDetails()
        this.loaddata(this.currentPage)
      }
    },
    signShow(item) {
      this.signa = '';
      this.signa = item;
      UIkit.modal('#sign-modal').show();
    },
    callZip() {
      UIkit.modal('#zipzip').show();
    },
    callZipAll(){
      UIkit.modal('#zipzipall').show();
    },
    zipZip2(event){
      if (event.target.files[0].type == 'application/x-zip-compressed') {
        this.zipAll.zipFile = event.target.files[0];
        this.zipAll.fileName = event.target.files[0].name
      }
    },
    zipZip(event){
      if (event.target.files[0].type == 'application/x-zip-compressed') {
        this.zip.zipFile = event.target.files[0];
        this.zip.fileName = event.target.files[0].name
      }
    },
    async offlineData() {
      try {
        const response = await ExamService.offlineData({examId: this.examId});
        if (response.data.status === 200) {
          if (response.data.success == true){
            window.location.href = response.data.result.url
            this.loaddata(this.currentPage)
          }
          else{
            alert(response.data.message);
          }
        } else {
          alert(response.data.message)
        }

      } catch (e) {
        console.log(e)
      }
    },
    async resZip() {
      try {
        UIkit.modal('#zipzip').hide();
        let formData = new FormData();

        if (this.zip.fileName == '') {
          if (this.$i18n.locale == 'kr')
          {
            alert("zip 파일을 선택하세요.");
          }
          else{
            alert("Please choose zip file.");
          }
          return;
        }
        formData.append('examId', this.examId);
        formData.append('file', this.zip.zipFile);
        const response = await ExamService.studentResultZip(formData);
        if (response.data.status === 200) {
         if (response.data.success == true){
           if (this.$i18n.locale == 'kr')
           {
             alert("결과를 업로드 하였습니다.");
           }
           else{
             alert("Zip upload is done.");
           }
           this.loaddata(this.currentPage)
         }
         else{
             alert(response.data.message);
         }
        } else {
          alert(response.data.message)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async resZipAll() {
      try {
        UIkit.modal('#zipzip').hide();
        let formData = new FormData();

        if (this.zipAll.fileName == '') {
          if (this.$i18n.locale == 'kr')
          {
            alert("zip 파일을 선택하세요.");
          }
          else{
            alert("Please choose zip file.");
          }
          return;
        }
        formData.append('examId', this.examId);
        formData.append('file', this.zipAll.zipFile);
        const response = await ExamService.studentResultZipAll(formData);
        if (response.data.status === 200) {
          if (response.data.success == true){
            if (this.$i18n.locale == 'kr')
            {
              alert("결과를 업로드 하였습니다.");
            }
            else{
              alert("Zip upload is done.");
            }
            this.loaddata(this.currentPage)
          }
          else{
            alert(response.data.message);
          }
        } else {
          alert(response.data.message)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async resetUser() {
      try {
        const response = await ExamService.resetUser({
          examId: this.examId,
          examCode: this.stuCode,
          userId: this.stuUID,
          finish: this.stuFinish,
          variant: this.stuVariant
        });
        UIkit.modal('#validation').hide();
        alert(response.data.message)
        this.loaddata(1)
      } catch (e) {
        console.log(e)
      }
    },
    async valiReset(item) {
      this.stuName = item.name
      this.stuCode = item.exam_code
      this.stuFinish = item.finish
      this.stuUID = item.user_id
      if (item.variant == null) {
        this.stuVariant = 'A'
      } else {
        this.stuVariant = item.variant
      }
      UIkit.modal('#validation').show();
    },
    async logs(item) {
      try {
        const response = await ExamService.logs({
          examId: this.examId,
          examCode: item.exam_code,
          userId: item.user_id
        });
        this.logData.name = item.name;
        this.logData.examCode = item.exam_code;
        this.logData.logs = response.data;
        UIkit.modal('#validation').hide();
      } catch (e) {
        console.log(e)
      }
    },
    getNow: function () {
      const today = new Date();
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + ' ' + time;
      this.currentDate = dateTime;
    },
    listCount() {
      this.currentPage = 1;
      this.loaddata(this.currentPage)
    },
    statusSearch() {
      this.currentPage = 1;
      this.loaddata(this.currentPage)
    },
    deporderTable(order) {
      this.deptableorder.sort = order;
      this.deptableorder.ascordesc = !this.deptableorder.ascordesc;
      if (this.deptableorder.ascordesc) {
        this.depascordesc = 'asc'
      } else {
        this.depascordesc = 'desc'
      }
      this.depPage = 1;
      this.groupView(this.depPage)
    },
    checkOrder(item){
      if(item == 'alert_cnt' || item == 'score'){
        this.ascordesc = 'desc'
      }
      else {
        this.ascordesc = 'asc'
      }
      this.tableorder.sort = item;
      this.loaddata(1)
    },
    orderTable(order) {
      this.tableorder.sort = order;
      this.tableorder.ascordesc = !this.tableorder.ascordesc;
      if (this.tableorder.ascordesc) {
        this.ascordesc = 'asc'
      } else {
        this.ascordesc = 'desc'
      }
      this.currentPage = 1;
      this.loaddata(this.currentPage)
    },
    gridview() {
      window.open("/" + this.$i18n.locale + "/organization/Organization/Examinee/ResultGrid/" + this.examId,'_blank',
          'width=1440,height=880')
    },
    frameclose() {
      this.videoframe = ''
      this.videosUrl = []
      this.frameinfo.display = false;
    },
    moreDetails(data) {
      this.moreinfo.avg_acc = data.avg_accuracy;
      this.moreinfo.max_acc = data.max_accuracy;
      this.moreinfo.avg_acc_id = data.avg_accuracy_id;
      this.moreinfo.max_acc_id = data.max_accuracy_id;
      this.moreinfo.client = data.client;
      this.moreinfo.photo = data.photo;
      this.moreinfo.photo_id = data.photo_id;
      this.moreinfo.image = data.image;
      this.moreinfo.examineeName = data.name;
      this.moreinfo.examineeId = data.user_id;
      this.moreinfo.username = data.username;
      this.moreinfo.examtime = 100;
      this.moreinfo.display = true;
      // UIkit.modal('#moreinfo').show();
    },
    toggleSelect: function () {
      this.userIds = [];
      if (this.allSelected) {
        this.allSelected = false
      } else {
        this.allSelected = true
      }
      if (this.allSelected) {
        for (let i = 0; i < this.examinee.length; i++) {
          if (this.examinee[i].name != '' && this.examinee[i].finish == '0' && this.examinee[i].started_date != null) {
            this.userIds.push(this.examinee[i].user_id);
          }
        }
      }
    },
    select: function () {
      if (this.allSelected) {
        this.allSelected = false;
      }
    },
    extraTimeModal() {
      UIkit.modal('#studentExtraTime').show()
    },
    async extraTimeSend() {
      try {
        if (this.exam.extraTime != '') {
          var userItems = [];
          if (this.userIds.length > 0) {
            for (let j = 0; j < this.userIds.length; j++) {
              for (let i = 0; i < this.examinee.length; i++) {
                if (this.examinee[i].user_id != null) {
                  if (this.userIds[j] == this.examinee[i].user_id) {
                    let item = {
                      ecode: this.examinee[i].exam_code,
                      uid: this.examinee[i].user_id,
                      variant: 'A'
                    };
                    userItems.push(item)
                  }
                }
              }
            }
          } else {
            alert("Examinee not found!")
          }
          const response = await ExamService.extraTimeSet({
            examId: this.examId,
            extraTime: this.exam.extraTime * 60,
            users: userItems
          });

          if (response.data.status == 200) {
            alert("successfully sending extra time");
            UIkit.modal('#studentExtraTime').hide()
          } else {
            alert("something wrong");
            UIkit.modal('#studentExtraTime').hide()
          }
        } else {
          alert("Please put extra time!!")
        }
      } catch (error) {
        console.log(error)
      }
    },
    mediaDownloadURL(item) {
      window.open('https://www.nsdai.org/' + item, '_blank')
    },
    async currentMedia(name, loginID, examtime, check) {
      try {
        this.moreinfo.display = false;

        let formData = new FormData();

        formData.append('ExamName', this.examDetail.examName);
        formData.append('ExamTime', examtime);
        formData.append('VideoQuality', 0.05);
        formData.append('StudentName', name);
        formData.append('StudentID', loginID);
        formData.append('ExamID', this.examId);
        formData.append('IfSaveStillImages', 'False');
        formData.append('SaveStillImagIntervalSec', '30');
        var _this = this;
        axios.post('https://nsdai.org/GetExamDetailForUBT', formData).then(function (response) {
          if (check != "currentMedia")
          {
            if (response.data.exam.status == 10) {
              if (response.data.files == null) {
                alert("there is no file")
              } else {
                _this.videosUrl = response.data.files;
                _this.frameinfo.display = true;
              }
            } else {
              alert("The file upload is not completed")
            }
          }
          else{
            _this.videoframe = "https://nsdai.org/LiveServerRedirect?id=" +
                response.data.exam.id;
            _this.frameinfo.display = true;
          }
        });
      } catch (error) {
        console.log(error)
      }
    },
    searchFilter() {
      this.currentPage = 1;
      this.loaddata(this.currentPage);
    },
    closeModal(item) {
      if (item == 'all') {
        UIkit.modal('#download').hide();
        window.location.href = '/uploadingDir/report/' + this.downloadUrl
      }
      if (item == 'student') {
        UIkit.modal('#student').hide();
        window.location.href = '/uploadingDir/report/' + this.studentUrl
      }
    },
    async jsonDownload() {
      try {
        const response = await ExamService.getJsonDownload({
          examId: this.examId, variant: this.selectVar});
        if (response.data.status == 200) {
          if (response.data.success == true) {
            let name = response.data.result.fileName;
            var jsons = JSON.stringify(response.data.result.examineesJson);
            jsons = [jsons];
            var blob1 = new Blob(jsons, {type: "text/plain;charset=utf-8"});
            var isIE = false || !!document.documentMode;
            if (isIE) {
              window.navigator.msSaveBlob(blob1, name + ".json");
            } else {
              var url = window.URL || window.webkitURL;
              var link = url.createObjectURL(blob1);
              var a = document.createElement("a");
              a.download = name + ".json";
              a.href = link;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
          } else {
            alert(response.data.message)
          }
        } else {
          alert(response.data.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    resultDownload() {
      UIkit.modal('#drc').show()
    },
    async rDown(item){
      var offset = new Date().getTimezoneOffset();
      var ncd = offset / 60;
      // eslint-disable-next-line no-unused-vars
      var zone = 'Asia/Seoul';

      if (ncd == '-7') {
        zone = 'Asia/Jakarta';
      }
      if (ncd == '-8') {
        zone = 'Asia/Ulaanbaatar';
      }
      if (ncd == '-9') {
        zone = 'Asia/Seoul';
      }

      if (item == 'Excel'){
        try {
          const response = await ExamService.getExcelDownload({
            examId: this.examId,
            tZone: zone,
            variant: this.selectVar
          });
          if (response.data.status == 200) {
            if (response.data.success == true) {
              // this.downloadUrl = response.data.result.downloadUrl;
              // UIkit.modal('#download').show()
              window.location.href = '/uploadingDir/report/' + response.data.result.downloadUrl;
            }
          }
        } catch (error) {
          console.log(error)
        }
      }
      else{
        try {
          const response = await ExamService.getFilesDownload({
            examId: this.examId,
          });

          if (response.data.status == 200) {
            if (response.data.success == true) {
              // this.downloadUrl = response.data.result.file;
              window.location.href = '/uploadingDir/' + response.data.result.file
            }
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    async examDetails() {
      try {
        this.examDetail = {};
        const response = await ExamService.getExamDetails(this.examId,
            { grpId: this.activeGroup.id, orgId : this.currentUser.organizs[0].organizId} );
        if (response.data.status == 200) {
          response.data.result.examDetail.datasize = parseInt(response.data.result.examDetail.datasize)
          response.data.result.examDetail.datasize = response.data.result.examDetail.datasize / 1024 / 1024
          response.data.result.examDetail.datasize = response.data.result.examDetail.datasize.toFixed(2)
          this.examDetail = response.data.result.examDetail;
          this.examVar = response.data.result.examDetail.variants
        }
      } catch (error) {
        console.log(error)
      }
    },

    popUpStudentDetail(userId, examCode, variant) {

      this.$setCookie('utoken', this.getToken);
      this.$setCookie('urole', this.currentUser.authorities[0].authority);
      this.$setCookie('uorganizId', this.currentUser.organizs[0].organizId);
      var setvar = ''
      if (window.location.host.includes('kr')) {
        this.$i18n.locale = 'kr'
      }

      if (variant == null)
        setvar = this.selectVar
      else
        setvar = variant
      let lang = this.$i18n.locale== "kr" ? "ko" : this.$i18n.locale;
      window.open(this.localPath + "/examinee_result" + "?lang=" + lang + "&examId=" + this.examId
          + "&examCode=" + examCode + "&variant=" + setvar + "&userId=" + userId, '_blank', 'width=1100,height=750')
    },
    notfinish() {
      alert("Exam not finished in mobile !!")
    },
    ublMeet(){
      /* eslint-disable */
        var roomID = this.activeGroup.id + "_" + this.examId
        OpenMeet(roomID, null, this.currentUser.firstName, this.currentUser.username)
      // window.open("https://nsdai.org/UBTMeetHeadPos-Meet?RoomId=" + this.activeGroup.id + "_" + this.examId)
    },
    async loaddata(page) {
      try {
        if (page > 0) {
          page = page - 1;
        }
        let item = {
          page: page,
          examId: this.examId,
          name: this.exam.examineeName,
          examCode: this.exam.examCode,
          filter: this.filter,
          variant: this.selectVar,
          sortfield: this.tableorder.sort,
          size: this.size,
          groupId: this.activeGroup.id,
          ascordesc: this.ascordesc,
          finish: this.statusfinish
        };
        const response = await ExamService.getExamineeResultGrid(item);
        if (response.status == 200) {
          var qrIds = []
          for (let i = 0; i < response.data.content.length; i++) {
            response.data.content[i].checked = false
            if (response.data.content[i].qr_content != '')
            {
              var rep = response.data.content[i].qr_content.split('|')
              response.data.content[i].qrId =  rep[1]
              qrIds.push(rep[1])
            }
          }
          if (qrIds.length > 0)
          {
            if (qrIds.length == 1){
              qrIds.push('')
            }
            let formData = new FormData();

            formData.append('ids', qrIds);
            formData.append('secret', 'ilovensdevil123');
            // var _this = this;

            axios.post('https://nsdai.org/GetStatusFromUBT-bulk', formData).then(function (bulk) {
              for (let i = 0; i < response.data.content.length; i++)
              {
                for (let j = 0; j < bulk.data.status.length; j++) {
                  if (response.data.content[i].qrId == bulk.data.status[j][0]) {
                    response.data.content[i].qrStatus = bulk.data.status[j][1]
                  }
                }
              }
            });
          }
          var _this = this

          setTimeout(function (){
            _this.examinee = response.data.content;
          }, 500)
          this.pages = response.data.totalPages;
          this.totalElements = response.data.totalElements;
          if (!this.examinee.length) {
            this.isLoading = false;
          }
        }
      } catch (error) {
        console.log(error)
        this.error = error.response.data.message;
        this.scrollToTop();
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    deppageselect(item) {
      this.depPage = item
      this.groupView(this.depPage)
    },
    pageselect(item) {
      this.currentPage = item;
      this.loaddata(this.currentPage)
    },
    onChange: function () {
      this.currentPage = 1;
      this.loaddata(this.currentPage)
    },
    reload() {
      this.examDetails();
      this.loaddata(this.currentPage)
    },
    defaultSetting() {
      this.filter = '';
      this.size = 25;
      this.currentPage = 1;
      this.loaddata(this.currentPage);
    },
  }
};
</script>
<style scoped>
table tr .hidden {
  display: none;
}

.uk-table td {
  padding: 7px 12px;
}

.uk-table-small th, .uk-table-small td {
  padding: 8px 3px;
}

.sortBy {
  opacity: 0;
}

.changed {
  background: #0d9df9;
  color: white;
}

.btn-sortBy {
  outline: none;
  padding: 0px 5px 0px 5px;
}

.hidden {
  border-top: 1px solid #dee2fe;
  border-bottom: 1px solid #dee2fe;
}

.red {
  background-color: red;
}

.blue {
  background-color: blue;
}

.scroll {
  height: 400px;
  overflow: auto;
}

.uk-label.uk-label-primary {
  background-color: #01b0ff
}

.uk-label {
  padding: 5px 10px;
}

.uk-button-primary {
  background-color: #01b0ff
}

.uk-table td.subtit {
  border: 1px solid #dee2e6;
  text-align: center;
  font-weight: 500;
  vertical-align: middle;
  font-size: 14px;
}

.sortBy {
  opacity: 0;
}

.btn-sortBy {
  outline: none;
  padding: 0px 5px 0px 5px;
}

.uk-table tr {
  border: 1px solid #dee2e6;
}

.uk-table td.subtits {
  border: 1px solid #dee2e6;
  text-align: center;
  font-weight: 500;
  vertical-align: middle;
  padding-left: 10px;
  /* color: #404348; */
  font-size: 14px;
  /* background-color: #eeeeef; */
}

.uk-table tr td {
  vertical-align: middle;
}

.uk-table tr.col {
  border-bottom: 1px solid #dee2fe;
  vertical-align: middle;
  border-top: 1px solid #dee2fe;
  font-size: 13px;
}

.uk-table th {
  vertical-align: middle;
  color: #fff;
}

.qList {
  height: 20px;
  font-size: 1.4em;
  font-weight: 800;
  color: rgb(1, 176, 255);
}

.qLists {
  border-bottom: 3px solid rgb(1, 176, 255);
  padding-bottom: 10px;
  width: auto;
  height: 20px;
  font-size: 1.2em;
  font-weight: 800;
  color: rgb(1, 176, 255);
}

.detailsStudent {
  border: 1px solid #dee2e6;
  padding-top: 10px;
  padding-bottom: 10px;
}

.qbtn_d {
  width: 120px;
  background-color: #01b0ff;
  border: 1px solid #fff;
  display: inline-block;
  padding: 0px 10px;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  min-width: 30px;
  text-align: center;
}

.tit {
  color: #0276ac;
  font-weight: 600;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
}

.tit2 {
  text-align: left;
  font-size: 14px;
}

.qbtn_b {
  background-color: #01b0ff;
  border: 1px solid #fff;
  display: inline-block;
  padding: 0px 10px;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  min-width: 30px;
  text-align: center;
}

.searchIcon >>> svg {
  width: 28px;
  margin: 5px;
  color: #fff;
  background: #01b0ff;
}

/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  padding: 15px 10px;
  width: 700px;
  max-width: calc(100% - 0.01px) !important; /* Full width */
  margin-bottom: 100px;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

td{
  border: 1px solid #dee2de !important;
}
.white{
  background: #ffffff;
}
</style>
